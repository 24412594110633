import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class VideoBanner extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="video-banner-area youtube-bg">
			  <div className="video-banner-content-wrap">
			    <div className="container">    
			      <div className="video-banner-content text-center">
			        <h2 className="main-title">Carbury Castle</h2>
							<p>Regarded as potentially the site of the first Norman castle<sup>[1]</sup>, Carbury Castle is situated
							on Carbury Hill in Kildare. It was strategically located close to the Boyne and the border between the ancient territories
							of Mide and Laigin.</p>
							<p>The castle was deserted by the last residents, the Colley family, around 250 years ago<sup>[2]</sup>. It has been
							falling into ruin ever since.</p>
							<br/>
							<p>An application for Community Monument Funding of €30,000 in 2024,
								which would produce a report on what work is needed to protect Carbury Castle, was unsuccessful.</p>
							<br/>
							<p>Cllr. Brendan Wyse has committed to allocating Local Property Tax for this effort, if he is re-elected
								on June 7th. In the meantime, if there is a benefactor who is interested in this project, then please get
								in touch.</p>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default VideoBanner